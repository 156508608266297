/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React from "react"

import { H1, H3, P, P2 } from "../components/EasyText"

import { Paper, Box, Tooltip, makeStyles } from "@material-ui/core"
import Link from "./Link"

import DownloadIcon from "mdi-react/DownloadOutlineIcon"

import Videos from "./videos"

const useStyles = makeStyles((theme) => ({
  quote: {
    borderLeft:
      theme.palette.type === "light" ? "#0003 8px solid" : "#fff3 8px solid",
    margin: "0",
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  hint: {
    color: theme.palette.text.hint,
  },
  section: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(3),
    },
  },
  container: {
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      boxSizing: "border-box",
      overflow: "hidden",
      "&::after": {
        boxSizing: "content-box",
        display: "block",
        content: "''",
        width: `calc(100% + ${2 * theme.spacing(2)}px)`,
        paddingBottom: "calc(56.25% + 2px)" /* 16:9 */,
        marginLeft: -theme.spacing(2),
      },
    },
  },
  ytPaper: {
    [theme.breakpoints.down("xs")]: {
      boxShadow: "none",
    },
  },
  ytWrap: {
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      left: 0,
      right: 0,
    },
    paddingBottom: "56.25%" /* 16:9 */,
    height: 0,
    transform: "translateZ(0)",
    minWidth: "100%",
  },
  ytFrame: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transform: "translateZ(0)",
  },
  pdf: { maxHeight: "90vh", height: 600, width: "100%" },
}))

export default function HomePageContent() {
  const classes = useStyles()

  return (
    <>
      <H1 gutterBottom id="top">
        Oathall Leavers 2020
      </H1>

      <Box className={classes.section} clone>
        <Paper variant="outlined" component="section">
          <H3 component="h2" id="goodbye" gutterBottom>
            Goodbye Message
          </H3>

          <blockquote className={classes.quote}>
            <P paragraph>
              <b>The past five years have been the best years of our lives.</b>{" "}
              From when we were all just 11 years old, to now when we are 16 (or
              15), we've always been supporting and looking after one another.
              Every year has had its ups and downs, and we've all made mistakes,
              but those are the things that make us, us.
            </P>
            <P paragraph>
              After <b>Friday 20 March 2020</b>, it's very likely that some of
              us will never see each other again, as we each move to different
              colleges, universities, towns, and even countries. I know that
              most people won't end up staying in contact, but never be afraid
              to get back in touch even after days, weeks, months, or maybe
              years.
            </P>
            <P paragraph>
              On Friday, most of us were able to sit through the Graduation
              Assembly but some never did, due to self-isolation from COVID-19,
              so you can find a full copy of everything from the assembly below.
              I'm sure we won't ever forget that we're the COVID Kids/Corona
              Kids/Class of Corona but everyone else will.
            </P>
            <P paragraph>
              This website should, theoretically, remain up forever but don't
              rely on it! Get people's contact details: phone numbers, emails.
            </P>
            <footer className={classes.hint}>
              <P paragraph>
                Good luck in the future,
                <br />
                <b>The Leavers Team</b>
              </P>
              <P2>
                David Wheatley, Benjamin McDowell, Felix Carter, Kitty
                Underhill, Jess Ruddock, and Lottie Neighbour
              </P2>
            </footer>
          </blockquote>
        </Paper>
      </Box>
      <Box className={classes.section} clone>
        <Paper variant="outlined" component="section">
          <H3 id="presentation" component="h2" gutterBottom>
            Leavers Assembly Presentation
          </H3>

          <P2 paragraph>
            If the embedded presentation below doesn't work correctly, you can
            download the presentation in PDF or PowerPoint (PPTX) format.
          </P2>

          <Box display="flex" justifyContent="center" mx="auto">
            <Tooltip title="Download PDF file">
              <Box m={1}>
                <Link
                  color="secondary"
                  variant="outlined"
                  linkIsButton
                  to="/graduation-day-assembly.pdf"
                  hasExternalLinkIcon={false}
                  target="_blank"
                >
                  <DownloadIcon />
                  PDF
                </Link>
              </Box>
            </Tooltip>
            <Tooltip title="Download PowerPoint file">
              <Box m={1}>
                <Link
                  color="secondary"
                  variant="outlined"
                  linkIsButton
                  to="/graduation-day-assembly.pptx"
                  hasExternalLinkIcon={false}
                  target="_blank"
                >
                  <DownloadIcon />
                  PPTX
                </Link>
              </Box>
            </Tooltip>
          </Box>
          <Box mt={4}>
            <object
              data="graduation-day-assembly.pdf"
              type="application/pdf"
              className={classes.pdf}
            >
              <embed
                src="graduation-day-assembly.pdf"
                type="application/pdf"
                className={classes.pdf}
              />
            </object>
          </Box>
        </Paper>
      </Box>
      <Box className={classes.section} clone>
        <Paper
          variant="outlined"
          component="section"
          className={classes.container}
        >
          <H3 id="fitzy" component="h2" gutterBottom>
            Mr Fitzpatrick's Goodbye
          </H3>
          <Paper className={classes.ytPaper} elevation={16}>
            <div className={classes.ytWrap}>
              <iframe
                className={classes.ytFrame}
                src="https://www.youtube-nocookie.com/embed/qOcj82np0H8"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                type="text/html"
                allowFullScreen="allowfullscreen"
              />
            </div>
          </Paper>
        </Paper>
      </Box>
      <Box className={classes.section} clone>
        <Paper
          variant="outlined"
          component="section"
          className={classes.container}
        >
          <H3 id="induction" component="h2" gutterBottom>
            2015 Induction Day
          </H3>
          <Paper className={classes.ytPaper} elevation={16}>
            <div className={classes.ytWrap}>
              <iframe
                className={classes.ytFrame}
                src="https://www.youtube-nocookie.com/embed/W_VdDkK9NTA"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                type="text/html"
                allowFullScreen="allowfullscreen"
              />
            </div>
          </Paper>
        </Paper>
      </Box>
      <Box className={classes.section} clone>
        <Paper variant="outlined" component="section">
          <H3 id="thomas" component="h2" gutterBottom>
            Thomas's Speech
          </H3>

          <P2 paragraph>
            So, here we are, at the probably the most abrupt potential end of
            the year. Nobody knows what's going to happen or what we're going to
            do. But <b>try not to panic</b>, whilst we don't know what we've yet
            to face, you've got everyone behind you.
          </P2>
          <P2 paragraph>
            I wanted to talk to you to take a moment to reflect, but most
            importantly celebrate and highlight all we've done. Everyone here
            has made it, although it's a bit cut short, we've made it. Most of
            us joined here in year 7, a bit clueless and quite nervous, and if
            anybody asked you to predict where you'd be in 5 years,{" "}
            <b>
              I'm certain that nobody could have predicted every memory and
              incident that has occurred throughout our journeys
            </b>
            . Cherish these, for these are all what have made you into the
            people you are today. Everything, good and bad, has either impacted
            you more than you may think, either taught you a lesson or made a
            friend that's going to last you the rest of your life.
          </P2>
          <P2 paragraph>
            I'm sure all of you have a particular memory or experience that
            you'd like to forget. <b>I'd quite happily like to forget that</b>{" "}
            (all of Year 7). But appreciate it also. In 30 years, when I look
            back at that, I'll find it just as amusing as everyone else does.
            The embarrassing stuff that happened to you now isn't going to haunt
            you forever.
          </P2>
          <P2 paragraph>
            Looking forward, I don't have any answers for you right now, but
            that doesn't mean it's going to stay that way.{" "}
            <b>This uncertainty won't last forever.</b> If it's really got to
            you and you're having a pretty atrocious time, remember that it
            doesn't last forever either. Try to keep in touch with people during
            this time. We could all use a little extra kindness and support.
          </P2>
          <P2 paragraph>
            <b>
              Next, I would like to say a massive thank you to all of our tutors
              and teachers during this 5 years.
            </b>{" "}
            To the tutors, who all saw us come in from year 7, and develop into
            the people we are now. Thank you for your unrequited support every
            day. And to all the teachers, who pushed us, and in whose classes a
            lot of memories that will stay with us for a very long time were
            made. I'd like to personally thank you for the drive you instilled
            me with, which will stick with me for a very long time to come.
          </P2>
          <P2 paragraph>
            We have been asked to become adults when frankly all we want to do
            is enjoy the last of our childhood.{" "}
            <b>We have been asked to put our complete education on hold.</b>{" "}
            This isn't fair, and it won't be forgotten. However, we are capable
            of doing this, and we will get through this. This will not get the
            better of us, I can assure you that.
          </P2>
          <P2 paragraph>
            Finally, I'd like to say thank you to you, the students. You are the
            people I've grown up with, and the people around you are the people
            you grew up with. If I don't see you again, thank you for
            everything. <b>I won't forget you.</b> We have all started in the
            school, regardless of when it was in Year 7 or Year 9 or Year 10,
            we'll all leave together. It's been a pleasure, and I wish all of
            you the best.
          </P2>
          <footer className={classes.hint}>
            <P>Thomas Mukasa</P>
          </footer>
        </Paper>
      </Box>

      <Videos classes={classes} />

      <Box className={classes.section} clone>
        <Paper
          variant="outlined"
          component="section"
          className={classes.container}
        >
          <H3 id="contact" component="h2" gutterBottom>
            Contact Us
          </H3>
          <P>
            If you'd like to get in touch with the Leavers Team, for whatever
            reason, contact us at{" "}
            <Link
              hasExternalLinkIcon={false}
              to="mailto:oathallleavers2020@gmail.com"
            >
              oathallleavers2020@gmail.com
            </Link>
            .
          </P>
        </Paper>
      </Box>
    </>
  )
}
