import React, { useState } from "react"
import PropTypes from "prop-types"

import {
  Paper,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Fab,
} from "@material-ui/core"
import Link from "./Link"
import { makeStyles } from "@material-ui/styles"

import ExpandMore from "mdi-react/ExpandMoreIcon"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    marginTop: theme.spacing(2),
    overflow: "hidden",
    zIndex: 999,
    maxHeight: `calc(100vh - 52px - 64px - ${theme.spacing(2)}px)`,
    overflowY: "auto",
    "@media (max-width: 1499px)": {
      position: "fixed",
      top: 72,
      right: theme.spacing(),
      width: "calc(100% - 16px)",
      maxWidth: 350,
      transform: `translateX(calc(100% + ${theme.spacing()}px))`,
      transition: theme.transitions.create("transform"),
    },
    "@media (min-width: 1500px)": {
      float: "left",
      top: 16,
      left: theme.spacing(),
      position: "sticky",
      width: "calc((100% - 960px) / 2)",
      minWidth: 250,
      maxWidth: `max-content`,
    },
    "@media (max-width: 599.95px)": {
      top: 64,
    },
  },
  panelOpen: {
    "@media (max-width: 1499px)": {
      transform: `translateX(0px)`,
    },
  },
  header: {
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 15,
    userSelect: "none",
    background: theme.palette.background.paper
    ,
  },
  showContentsButton: {
    display: "none",
    transform: "rotate(90deg)",
    "@media (max-width: 1499px)": {
      display: "flex",
      position: "fixed",
      top: 72 + theme.spacing(2),
      right: theme.spacing(),
      zIndex: 9999,
      transition: theme.transitions.create("transform"),
      "&[data-open=true]": {
        transform: "rotate(-90deg)",
        background: "transparent !important",
        boxShadow: "none",
        color: theme.palette.text.primary,
      },
    },
    "@media (max-width: 599.95px)": {
      top: 64 + theme.spacing(2),
    },
  },
}))

export default function PageNavSidebar({ menuItems }) {
  const classes = useStyles()

  const [SidebarOpen, setSidebarOpen] = useState(false)

  const ToggleSidebar = () => {
    setSidebarOpen(!SidebarOpen)
  }

  // useEffect(() => {
  //   if(SidebarOpen){

  //   }
  // }, [SidebarOpen])

  return (
    <>
      <Fab
        className={classes.showContentsButton}
        color="primary"
        data-open={SidebarOpen}
        onClick={ToggleSidebar}
        aria-label="Toggle contents sidebar"
      >
        <ExpandMore />
      </Fab>

      <Paper
        onClick={(e) => {
          setSidebarOpen(false)
          e.stopPropagation()
        }}
        variant="outlined"
        className={clsx(classes.root, SidebarOpen && classes.panelOpen)}
      >
        <List dense>
          <ListSubheader className={classes.header}>
            Page Contents
          </ListSubheader>

          {menuItems.map((item) => (
            <ListItem component={Link} to={item.anchor} key={item.anchor}>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </>
  )
}

PageNavSidebar.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.exact({
      text: PropTypes.string.isRequired,
      anchor: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}
