/* eslint-disable indent */
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HomePageContent from "../components/HomePageContent"
import PageNavSidebar from "../components/PageSidebar"
import { Container, Box } from "@material-ui/core"

const IndexPage = () => {
  return (
    <Layout>
      <PageNavSidebar
        menuItems={[
          { text: "Teachers' Video", anchor: "#teachers" },
          { text: "Photos Video", anchor: "#photos" },
          { text: "Teachers Bloopers", anchor: "#bloopers" },
          { text: "Goodbye Message", anchor: "#goodbye" },
          { text: "Leavers Presentation", anchor: "#presentation" },
          { text: "Mr Fitzpatrick's Goodbye", anchor: "#fitzy" },
          { text: "2015 Induction Day Video", anchor: "#induction" },
          { text: "Thomas's Speech", anchor: "#thomas" },
          { text: "Contact Us", anchor: "#contact" },
        ]}
      />
      <Container maxWidth="md">
        <Box component="main">
          <SEO />

          <HomePageContent />
        </Box>
      </Container>
    </Layout>
  )
}

export default IndexPage
