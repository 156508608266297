import React, { useState } from "react"
import { Box, Paper } from "@material-ui/core"
import { H3, P, H4, P2 } from "./EasyText"
import Link from "./Link"
import { useEffect } from "react"

export default function Videos({ classes }) {
  return (
    <>
      <Snapchat classes={classes} />
      <Box className={classes.section} clone>
        <Paper
          variant="outlined"
          component="section"
          className={classes.container}
        >
          <H3 align="left" id="teachers" component="h2" gutterBottom>
            Teachers' Video
          </H3>
          <Paper className={classes.ytPaper} elevation={16}>
            <div className={classes.ytWrap}>
              <iframe
                className={classes.ytFrame}
                src="https://www.youtube-nocookie.com/embed/uRlAbyh37T8"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen="allowfullscreen"
              />
            </div>
          </Paper>
        </Paper>
      </Box>
      <Box className={classes.section} clone>
        <Paper
          variant="outlined"
          component="section"
          className={classes.container}
        >
          <H3 align="left" id="photos" component="h2" gutterBottom>
            Photos Video
          </H3>
          <Paper className={classes.ytPaper} elevation={16}>
            <div className={classes.ytWrap}>
              <iframe
                className={classes.ytFrame}
                src="https://www.youtube-nocookie.com/embed/chQrfzNfZpI"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen="allowfullscreen"
              />
            </div>
          </Paper>
        </Paper>
      </Box>
      <Box className={classes.section} clone>
        <Paper
          variant="outlined"
          component="section"
          className={classes.container}
        >
          <H3 align="left" id="bloopers" component="h2" gutterBottom>
            Teacher Bloopers
          </H3>
          <Paper className={classes.ytPaper} elevation={16}>
            <div className={classes.ytWrap}>
              <iframe
                className={classes.ytFrame}
                src="https://www.youtube-nocookie.com/embed/73VjY8JdB7U"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen="allowfullscreen"
              />
            </div>
          </Paper>
        </Paper>
      </Box>
      <Box className={classes.section} clone>
        <Paper variant="outlined" component="section">
          <H3 align="left" id="bloopers" component="h2" gutterBottom>
            Credits
          </H3>
          <P>
            All of these videos were handcrafted by a group of skilled students.
            A massive thank you goes out to: Benjamin McDowell,{" "}
            <Link hasExternalLinkIcon={false} to="https://github.com/davwheat">
              David Wheatley
            </Link>
            , Felix Carter, Kitty Underhill, Lottie Neighbour, and Jess Ruddock.
          </P>
        </Paper>
      </Box>
      {/* </>
      )} */}
    </>
  )
}

function Snapchat({ classes }) {
  const [isSnapchat, setIsSnapchat] = useState(false)

  useEffect(() => {
    setIsSnapchat(
      typeof navigator !== "undefined" &&
        navigator.userAgent.toLowerCase().indexOf("snapchat") !== -1
    )
  }, [setIsSnapchat])

  return (
    <Box
      className={classes.section}
      style={{ display: isSnapchat ? "block" : "none" }}
      clone
    >
      <Paper variant="outlined" component="section">
        <H4 align="left" component="h2" color="error" paragraph>
          <strong>This won't work well in Snapchat!</strong>
        </H4>
        <P2 color="error">
          Tap the share button, then tap Open in Safari. If there's no share
          button, tap the 3 dots instead.
        </P2>
      </Paper>
    </Box>
  )
}
